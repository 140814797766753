<template lang="html">
    <div class="layout--main" >
    <template >
      
    </template>
    <!-- /Navbar -->

      <div class="">

        <div class="router-view">
          <div class="router-content">


              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                <!-- DROPDOWN -->
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>


            <div class="vista-inmo">
  <div  id="cotizacion" class="" >
     <div class="row w-full" v-if="valores.destino==0">
        <div class="">
            <div class="excel">
              <button  class="btn-regresar"  @click="$router.push('/')">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.892 14.861L12.234 14.861" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg><strong>Regresar</strong>
              </button>
           </div>
           </div>
        <div class="col-md-3 " align="center">
          <vx-card class=" cursor-pointer hoverClass" @click="destinoSeleccionado(1)">
            <feather-icon icon="HomeIcon" class="mr-150" style="width: 40px!important; height: 40px !important;margin: 2rem
;"/>
            <p>Comprar una casa</p>
          </vx-card>
        </div>
        <div class="col-md-3 " align="center">
          <vx-card class=" cursor-pointer hoverClass" @click="destinoSeleccionado(19)">
           <feather-icon icon="RefreshCcwIcon" class="mr-150 " style="width: 40px!important; height: 40px !important;margin: 2rem
;"/>
            <p>Mejora de hipoteca</p>
          </vx-card>
        </div>
            <div class="col-md-3 " align="center">
                <vx-card class=" cursor-pointer hoverClass" @click="destinoSeleccionado(2)" >
                   <feather-icon icon="SettingsIcon" class="mr-150" style="width: 40px!important; height: 40px !important;margin: 2rem
;"/>
                   <p>Construcción</p>
                </vx-card>
            </div>
            <div class="col-md-3 " align="center">
                <vx-card class=" cursor-pointer hoverClass" @click="destinoSeleccionado(5)">
                    <feather-icon icon="GridIcon" class="mr-150" style="width: 40px!important; height: 40px !important;margin: 2rem
;"/>
                    <p>Compra de terreno</p>
                </vx-card>
            </div>       
      </div>
       <Credito :destino="destino" :caso="caso" v-on:updateValores="sendDatosCredito($event)" v-if="!consultarBuro && !formSolicitante && valores.destino > 0"/>
        <Solicitante :caso="caso" v-if="formSolicitante && !consultarBuro" v-on:hacerConsultaBuro="udateConsultaBuro($event)"/> 
        <ConsultaBuro :caso="caso"  v-if="consultarBuro"/>
  </div>
            </div>
          </div>
        </div>
      </div>
<div class="footer">COPYRIGHT 2021 Cotizador SOC, todos los derechos reservados.</div>    </div>
  </div>


</template>      
<script>
import vSelect from 'vue-select'
import Credito from '@/views/CasoInmobiliaria/Credito.vue'
import TheNavbarVerticalInmobiliaria   from '@/layouts/components/navbar/TheNavbarVerticalInmobiliaria.vue'
import Solicitante from '@/views/CasoInmobiliaria/Solicitante.vue'
import ConsultaBuro from '@/views/CasoInmobiliaria/ConsultaBuro.vue'
export default {
  components: {
    TheNavbarVerticalInmobiliaria,
    vSelect,
    Credito,
    Solicitante,
    ConsultaBuro
  },
  data(){
		return{
       bancos:[
        { label: 'Afirme',  value: '12' },
        { label: 'ION FINA',  value: '21' },
        { label: 'Santander',  value: '10' },
        { label: 'HEY, BANCO',  value: '14' },//Antes Banregio
        { label: 'HSBC',  value: '6' },
        { label: 'Scotiabank',  value: '11' },
        { label: 'Banorte',  value: '4' },
        { label: 'Citibanamex',  value: '2' },
        { label: 'VE POR MÁS',  value: '16' },
      ],
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '15 Años',  value: '15' },
        { label: '10 Años',  value: '10' },
        { label: '5 Años',  value: '5' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      programas:[],
      id:'',
      valores:{
        destino:0,
        valor:'1,000,000',
        credito:'700,000',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '20 Años',  value: '20' }],
        bancos:[],
        gastosNotarial:3,
        plazo:'20'
      },
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      caso:{},
      formSolicitante:false,
      consultarBuro:false
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
  },
  watch: {
    valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    credito: function(newValue) {
      this.valores.credito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  computed:{
    validacion() {
      return this.valores.credito.replace(/,/g,"") > this.valores.valor.replace(/,/g,"") * 0.95
    },
    valor(){
      return this.valores.valor
    },
    credito(){
      return this.valores.credito
    }
  },
  methods:{
    destinoSeleccionado(destino){
      this.destino = destino
      this.valores.destino = destino
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
   
    
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    sendDatosCredito(e){ 
      
      //this.formSolicitante=true
      this.caso=e
      if(this.caso.regresar){
        this.valores.destino=''
        this.formSolicitante=false
      }else if(e.formSolicitante){
        this.formSolicitante=true
      }
    },
    udateConsultaBuro(e){

      this.caso=e
      this.valores=this.caso
      console.log(this.caso)
       if(this.caso.regresar){
       //console.log(this.valores)
        this.valores.destino=this.valores.destino
        this.consultarBuro=false
        this.formSolicitante=false
      }else if(e.consutarBuro){
        this.consultarBuro=true
      }
    }
  }
}
</script>

<style lang="css" scoped>
.select-custom{
  margin-top:0.5% !important;
}
.hoverClass:hover{
  background-color: #079DEF;
  color:white;
  
}
.feather-grid{
  height: 60px !important;
  width: 60px !important;
}
.feather-settings{
  height: 60px !important;
  width: 60px !important;
}
.feather-home {
  height: 60px;
  width: 60px;
}
.feather-refresh-ccw{
   height: 60px !important;
  width: 60px !important;
}

@media screen and (max-width:896px) {
  .col-md-3{
    width: 100%;
  }
  .vista-inmo{
    padding-top: 100px;
  }
  .vx-card{
    margin-bottom: 40px;
  }
  .footer{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
    background-color: #fff;
  }  
  
}
</style>
