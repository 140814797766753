<template>
        <div class="vista">
            <div class="row w-full">
            <div class="excel">
              <button  class="btn-regresar" @click="regresar()">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.892 14.861L12.234 14.861" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg><strong>Regresar</strong>
              </button>
           </div>
           </div>
            <vx-card>
            <div class="row w-full">
              <div class="col-md-4">
                <span class="span-placeholder">Correo electrónico</span>
                <input class="vs-input" v-validate="'required|email'" label="Correo electrónico" v-model="email" name="email" />
                <span class="text-danger span-placeholder" v-show="errors.has('email')">Campo requerido, debe ser un email válido</span>
                <span class="text-danger text-sm" v-if="emailState">*No se permite ingresar correos con dominio de SOC</span>  
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Nombre(s)</span>
                <input class="vs-input" v-validate="'required|alpha_spaces'"  v-model="nombre" name="nombres" />
                <span class="text-danger span-placeholder" v-show="errors.has('nombres')">Campo requerido, solo caracteres</span>
              </div>
              <div class="col-md-4">
                <span class="span-placeholder">Apellido Paterno</span>
                <input class="vs-input" v-validate="'required|alpha_spaces'"  v-model="apellidoPaterno" name="apellidoPaterno" />
                <span class="text-danger span-placeholder" v-show="errors.has('apellidoPaterno')">Campo requerido, solo caracteres</span>
              </div>
            </div>
            <div class="row w-full">  
              <div class="col-md-4">
                <span class="span-placeholder">Apellido Materno</span>
                <input class="vs-input" v-validate="'required|alpha_spaces'"  v-model="apellidoMaterno" name="apellidoMaterno" />
                <span class="text-danger span-placeholder" v-show="errors.has('apellidoMaterno')">Campo requerido, solo caracteres</span>
              </div>
              
              <div class="col-md-4">
                <span class="span-placeholder">Teléfono celular</span>
                <input class="vs-input" placeholder="Teléfono celular (10 dígitos)" v-model="telefono" name="telefono" v-validate="'required|min:10|max:10'" v-on:keypress="isNumber($event)"/>
                <span class="text-danger span-placeholder" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>
              </div>
               <div class="col-md-4" v-if="consultaBuro">
                <span class="span-placeholder">Consulta buró</span>
              <div class="flex justify-center">
                <div style="margin:2%;">
                <vs-radio v-model="consutarBuro" vs-value="1">Si</vs-radio>
                </div>
                <div style="margin:2%;">
                <vs-radio v-model="consutarBuro" vs-value="0">No</vs-radio>
                </div>
              </div>
            </div>
            <div class="row w-full">
              <div class="col-md-3">
                <vs-button color="primary" @click.prevent="registrarSolicitante()" :disabled="!validateForm" >Continuar</vs-button>
              </div>  
            </div>    
            </div>
            <br>
            </vx-card>
        </div>    
</template>
<script>
import TheNavbarVerticalPerfil   from '@/layouts/components/navbar/TheNavbarVerticalPerfil.vue'


export default {
  components: {
   TheNavbarVerticalPerfil
  },
  props: {
    caso: {
      type: Object,
      required: true
    },
  },
  data(){
		return{
      email:'',
      nombre:'',
      apellidoMaterno:'',
      apellidoPaterno:'',
      fechaNacimiento:'',
      telefono:'',
      consultaBuro:false,
      consutarBuro:true,
      valores:{
        consutarBuro:false,
        email:''
      }
    }
  },
  mounted(){
    this.getApikey()
    this.consultaABuro()

  },
  computed:{
    emailState() {
      return this.email.includes('socasesores') ? true : false
    },
    validateForm(){
      return this.email != '' && this.nombre != '' && this.apellidoPaterno != '' && this.apellidoMaterno != '' && this.telefono.length == 10 && !this.emailState
    }
  },
  methods:{
    regresar(){
      this.valores=this.caso
      this.valores.regresar=true
       console.log(this.valores)
      this.$emit('hacerConsultaBuro',this.valores)
    },
    numberFormat(number){
      return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
   consultaABuro(){
     let objRequestConsultaBuro = { 
      strApiKey: this.key,
      strMetodo: "BrokerConsultasBuro",
      objParametros : {		
        ApiKey : this.key
      }
    }
    this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.consultaBuro=true
            this.valores.consutarBuro=true
          }else{ 
            this.consultaBuro=false
            this.valores.consutarBuro=false
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
   },
    registrarSolicitante(){
      let self=this
      this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })
      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
      	  Nombres: this.nombre,
      	  ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
      	  TelefonoCelular: this.telefono,
      	  FechaNacimiento: '1992-01-01',
          StateData: 0,
        }
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.valores.email=this.email
                this.registrarCaso()
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        } else {
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          })
        }
      })
    },
    registrarCaso(){
      let self=this
      let objRequestRegistraCaso1= {}
      console.log(this.caso)
      if(this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          objCaso: {
            ValorInmueble : parseFloat(this.caso.valor.replace(/,/g,"")),
            SaldoInsoluto : parseFloat(this.caso.credito.replace(/,/g,"")),
            Plazos : [this.caso.plazo],
            Solicitante: this.email,
            Destino : this.caso.destino,
            Esquemas: this.caso.esquemas,
            PlazoRestante:this.caso.plazoRestante,
            PagoMensualActual: this.caso.pagoMensualActual,//parseFloat(this.caso.mensualidad.replace(/,/g,"")),
            Bancos:this.caso.bancos,
            PerfilCliente : 2,
            VersionOrigen:'Web'
          }
        }
      }else {
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          objCaso: {
            ValorInmueble : parseFloat(this.caso.valor.replace(/,/g,"")),
            MontoSolicitado : parseFloat(this.caso.credito.replace(/,/g,"")),
            Plazos : [this.caso.plazo],
            Solicitante: this.email,
            Destino : this.caso.destino,
            Esquemas: this.caso.esquemas,
            Bancos:this.caso.bancos,
            //Programas:[this.caso.programa],
            PerfilCliente : 2,
            VersionOrigen:'Web'
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
            if(this.consutarBuro==1 && this.valores.consutarBuro){
              this.valores.IdCaso=response.data.objContenido.Id
              this.valores.destino=this.caso.destino
              this.$emit('hacerConsultaBuro',this.valores)
              // window.open('http://localhost:8080/app/buroInmobiliaria/'+this.email, '_blank');
            }else{
              this.$router.push('/resultados/'+response.data.objContenido.Id).catch(() => {})
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
</style>
