<template>
    <div class="layout--main" >
    <template >
      <the-navbar-vertical-inmobiliaria/>
    </template>
    <div class="vista">
       <div class="row w-full">
            <div class="excel">
              <button  class="btn-regresar" @click="updateValores('regresar')">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.892 14.861L12.234 14.861" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg><strong>Regresar</strong>
              </button>
           </div>
           </div>
      <vx-card>
          <div class="row w-full ">
            <div class="col-md-4">
              <span class="span-placeholder">Valor de la propiedad</span>
              <div class="inputwrapper" data-required="MXM">              
                  <input  class="inputDataText" placeholder="$X,XXX,XXX.XX"  v-validate="'max:13'"  v-model="valores.valor" name="vv"  v-on:keypress="isNumber($event)"/>
                <!--<span class="text-danger span-placeholder" v-show="errors.has('vv')">Máximo son 10 digitos</span>-->
              </div>
            </div>
            <div class="col-md-4">
              <span class="span-placeholder">Monto del crédito</span>
              <div class="inputwrapper" data-required="MXM"> 
                <input class="vs-input" v-validate="'max:13'"  v-model="valores.credito" name="credito"  v-on:keypress="isNumber($event)"/>
                <!--<span class="text-danger span-placeholder" v-show="validacion">Debe ser máximo el 95% del valor de la propiedad. </span>-->
                <!--<span class="text-danger span-placeholder" v-show="errors.has('credito')">Máximo son 10 digitos</span>-->
              </div>
            </div>
          </div>  
          <div class="row w-full">  
             <div class="col-md-8" v-if="valores.destino==19">
                             <span class="span-placeholder">Pago mensual</span>
                <input class="vs-input" v-validate="'max:13'" label="Pago mensual" v-model="valores.pagoMensualActual" name="pagoMensual"  v-on:keypress="isNumber($event)"/>
                <!--<span class="text-danger span-placeholder" v-show="validacion">Debe ser máximo el 95% del valor de la propiedad. </span>-->
                <!--<span class="text-danger span-placeholder" v-show="errors.has('credito')">Máximo son 10 digitos</span>-->
            </div>
          </div>
          <div class="row w-full">  
            <div class="col-md-8" v-if="valores.destino==19">
                <span class="span-placeholder" for="plazos">Tiempo restante crédito</span>
                <select name="cars" id="plazos" v-model="valores.plazoRestante" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2);" >
                  <option :value='tipo.value' v-for="tipo in plazosRestantes"  :key="">{{tipo.label}}</option>
                </select>
            </div>
          </div>
          <div class="row w-full">  
            <div class="col-md-8">
              <p class="span-placeholder" id="title">Seleccionar un plazo</p>
                <div class="flex justify-between">
                 <!-- <ul class="centerx vx-row filtros">-->
                    <div v-for="filtro in plazos"  :key="filtro.value" style="margin:2%;">
                      <vs-radio v-model="valores.plazo" :vs-value="filtro.value" >{{filtro.label}}</vs-radio>
                    </div>
                 <!-- </ul>-->
                </div>  
            </div> 
            <div class="">
              <p class=" mb-2" id="title">Bancos</p>
               
              <div class="col-md-3" v-for="item in bancosEjecutivo" :key="item.value">
                <vs-checkbox v-model="valores.bancos" :vs-value="item.value" >{{ item.label }}</vs-checkbox>
              </div> 
            
            </div>
            <div class="col-md-3">
            <vs-button class="mt-4" color="primary" @click="updateValores()" :disabled="!validateForm" >Continuar</vs-button>
            </div>
          </div>
          
      </vx-card>
    </div>
    </div>
</template>
<script>
import vSelect from 'vue-select'
export default {
  props: {
    destino: {
      type: Number,
      required: true
    },
    caso:{
      type:Object,
      required: false
    }

  },
  components: {
    vSelect,
  },
  data(){
		return{
      bancosEjecutivo:[],
      plazosRestantes:[
        { label: '20 Años',  value: '20' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '15 Años',  value: '15' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '10 Años',  value: '10' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '5 Años',  value: '5' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '15 Años',  value: '15' },
        { label: '10 Años',  value: '10' },
        { label: '5 Años',  value: '5' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      programas:[],
      id:'',
      valores:{
        destino:1,
        valor:'1,000,000',
        credito:'700,000',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '20 Años',  value: '20' }],
        bancos:[],
        gastosNotarial:3,
        plazo:'20',
        formSolicitante:false,
        consultaBuro:false,
        pagoMensualActual:'',
        plazoRestante:'',
        regresar:false
      },
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ]
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.valores.destino=this.destino
    this.getBancosEjecutivo()
    if(this.caso.regresar){
      this.valores=this.caso
      this.valores.regresar=false
    }
    //this.updateValores()
  },
  watch: {
    valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    credito: function(newValue) {
      this.valores.credito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  computed:{
    validacion() {
      return this.valores.credito.replace(/,/g,"") > this.valores.valor.replace(/,/g,"") * 0.95
    },
    valor(){
      return this.valores.valor
    },
    credito(){
      return this.valores.credito
    }, 
    validateForm(){
      return this.valores.valor.length > 0 && this.valores.credito.length > 0 && this.valores.plazo > 0 && this.valores.bancos.length >= 1
    }
  },
  methods:{
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateValores(regresar){
      if(regresar){
        this.valores.regresar=true
      }else{
      this.valores.formSolicitante=true  
      }
      this.$emit('updateValores',this.valores)
    },
   
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    
    registrarCaso(){
      let self=this
      this.items=[]
      this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })
        let objRequestRegistraCaso1= {}
        let bancos=[]
        for (var i = 0; i < this.valores.bancos.length; i++) {
          bancos.push(parseInt(this.valores.bancos[i].value))
        }
        if(this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCaso',
            objCaso: {
              Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valor.replace(/,/g,"")),
              SaldoInsoluto : parseFloat(this.caso.credito.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: 'solicitante@socasesores.com',
              Destino : this.caso.destino,
              Esquemas:this.valores.esquemasACotizar,
              PlazoRestante:this.caso.plazoRestante,
              PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
              Bancos:bancos,
              PerfilCliente : this.caso.tasa
            }
          }
        }else {
          objRequestRegistraCaso1 = {
            strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
            strMetodo: 'RegistraCaso',
            objCaso: {
              Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.valores.valor.replace(/,/g,"")),
              MontoSolicitado : parseFloat(this.valores.credito.replace(/,/g,"")),
              Plazos : this.valores.plazo,
              Solicitante: 'solicitante@socasesores.com',
              Destino : 1,
              Esquemas:this.valores.esquemasACotizar,
              Bancos:bancos,
              PerfilCliente : this.valores.tasa
            }
          }
        }
        this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              localStorage.setItem('caso', response.data.objContenido.Id)
              this.id=response.data.objContenido.Id
              this.cotiza(response.data.objContenido.Id)
              this.dameCaso(true)
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraCaso',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
    },
    getBancosEjecutivo(){
      let self=this
      var objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: 'ListaFinancieras',
        objEjecutivo: {
          EMail: this.$store.state.AppActiveUser.EMail
        }
      }
      this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let bancos=response.data.objContenido
            for (var i = 0; i < bancos.length; i++) {
              this.bancosEjecutivo.push({label:bancos[i].Nombre, value: bancos[i].Id})
            }
            //this.caso.bancos=this.bancosEjecutivo
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFinancieras',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="css" scoped>

.col-md-3 {
    flex: 0 0 auto;
    width: 30%;
    float: left;
    margin: 5px;
}
</style>
