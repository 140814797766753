import { render, staticRenderFns } from "./TheNavbarVerticalPerfil.vue?vue&type=template&id=45c43826&scoped=true&"
import script from "./TheNavbarVerticalPerfil.vue?vue&type=script&lang=js&"
export * from "./TheNavbarVerticalPerfil.vue?vue&type=script&lang=js&"
import style1 from "./TheNavbarVerticalPerfil.vue?vue&type=style&index=1&id=45c43826&prod&scoped=true&media=screen&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c43826",
  null
  
)

export default component.exports