<template>

  <div class="relative">
 

    <div class="vx-navbar-wrapper" :class="classObj">

      <!--<vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">-->

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <!-- <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" /> -->
        <!--<vx-tooltip :text="'Botón de inicio'" position="left">
        <feather-icon icon="HomeIcon" @click="$router.push('/').catch(() => {})" v-if="current"/>
        </vx-tooltip>
        <vs-spacer />-->
        <vs-navbar class=" navbar-custom navbar-skelton">
            <input type="checkbox" class="openMovilMenu" id="openMovilMenu" style="display: none;">
              <label for="openMovilMenu" class="movilIconToggle">
                <div class="spinner2 diagonal part-1"></div>
                <div class="spinner2 horizontal"></div>
                <div class="spinner2 diagonal part-2"></div>
              </label>
              <div id="movilMenu">
                <div class="movilMenuInner ">
                  <br>
                  <div class=" sublink mt-4">
                    <a href="https://cotizador.socasesores.com/app/"  >Lista de casos</a>
                    <!--<router-link to="/" class="sublink" >Lista de casos</router-link>-->
                  </div>
                  <div id="impFav" class="sublink">
                    <a href="https://cotizador.socasesores.com/app/nuevaCotizacion" >Cotización rápida</a>
                    <!--<router-link to="/nuevaCotizacion" class="sublink">Cotización rápida</router-link>-->
                  </div>
                  <div class="sublink">
                    <a href="https://cotizador.socasesores.com/app/nuevoCaso">Nuevo caso</a>
                    <!--<router-link to="http://localhost:8081/app/nuevoCaso"  class="sublink" replace> Nuevo caso</router-link>-->
                  </div>
                   <div class="sublink">
                    <a href="https://cotizador.socasesores.com/app/reportesCredito"></a>
                    <!--<router-link to="http://localhost:8081/app/reportesCredito"  class="sublink" replace>Reportes de Crédito</router-link>-->
                  </div>
                   <div class="sublink">
                    <a href="https://cotizador.socasesores.com/app/PrecaSoc"></a>
                  </div>
                  <logout  />
                </div>
              </div>
        <div :text="'Botón de inicio'" class="logo-position"   position="left" > <!--Cambiar logo style="border:	#006D4E 2px solid;
                border-radius: 5px;"-->
         <a  class=" header-logo" @click="$router.push('/')">  <img src="../../../assets/images/pages/logo-soc-nuevo.png" alt="logo-soc"  style="margin: .5rem; height: 3.6rem; cursor:pointer;"/> </a><!--Cambiar logo style="margin: .5rem; height: 3.6rem;"-->
        </div>
        <vs-spacer />

        <!-- <search-bar />-->
        <!-- <feather-icon icon="TruckIcon" @click="$router.push('/autos').catch(() => {})" style="margin-right:2%"/> -->
        <!-- <feather-icon icon="HomeIcon" @click="$router.push('/autos').catch(() => {})" style="margin-right:2%" v-if="current"/> -->
        <!-- <notification-drop-down /> -->
        <div :text="'Menú para el perfil'" position="bottom">
        <profile-drop-down />
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import Bookmarks            from "./components/Bookmarks.vue"
import SearchBar            from "./components/SearchBar.vue"
import NotificationDropDown from "./components/NotificationDropDown.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"

export default {
  name: "the-navbar-vertical-perfil",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
  },
  data(){
		return{
      current:'/',
    }
  },
  // mounted(){
  //
  // },
  watch:{
    $route:function() {
      if(this.$router.currentRoute.path == '/'){
        this.current= false
      }else {
        this.current= true
      }
    }
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ? "#10163a" : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default")      return "navbar-default"
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced"
      else if (this.verticalNavMenuWidth == 'full')    return "navbar-full"
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  }
}
</script>
<style >

</style>
<style scoped media="screen">
.vx-navbar-wrapper{position: fixed;}
.navbar-full{
  width: 100% !important;
}
/*.header {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #033A74;
    position: fixed;
    height: 60px!important;
    overflow: hidden;
    z-index: 10;
}*/
.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}
.mainInner{
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}
.mainInner div{
    display:table-cell;
    vertical-align: middle;
    font-size: 3em;
    
    letter-spacing: 1.25px;
}
/*#sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 250px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: #006D4E;
    padding-top: 60px;
    
}
.sidebarMenuInner{
    margin:0;
    padding:0;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
    list-style: none;
    color: #fff;
    
    padding: 10px;
    padding-left: 20px;
    padding-top: 15px;
    cursor: pointer;
   border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li span{
    display: block;
    font-size: 14px;
}
.sidebarMenuInner li a{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}
input[type=checkbox] {
   display: none;
}
input:checked ~ #sidebarMenu {
    transform: translateX(0);
}

.wrap-all-the-things{
      width: 100%;
}
input:checked ~ .wrap-all-the-things {
    transform: translateX();
   
}




input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}
.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
}
.spinner2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}
.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}
.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}
 .vista{
     padding-top: 0px !important;
     padding-right: 50px;
 }
 .logo-position:hover{
    border:	#006D4E 2px solid;
    border-radius: 5px;    
}*/
</style>
