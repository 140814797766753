<template>
         <vx-card>
            <div class="row w-full">
              <div class="col-md-4">
              <span class="span-placeholder">Dirección (como aparece en la INE)</span>
              <div class="inputwrapper">              
                   <input class="vs-input"  label="Dirección (como aparece en la INE)" v-model="direccionIne" name="direccionIne" />
                <!--<span class="text-danger span-placeholder" v-show="errors.has('vv')">Máximo son 10 digitos</span>-->
              </div>
            </div>
              <!--<div class="vx-col  lg:w-3/5 sm:w-full m-3">-->
               
               <!-- <span class="text-danger span-placeholder" v-show="errors.has('direccionIne')">Campo requerido</span>-->
              <!--</div>-->
              <div class="col-md-4">
              <span class="span-placeholder">Municipio</span>
                <input class="vs-input"  label="Municipio" v-model="municipio" name="municipio" />
               <!-- <span class="text-danger span-placeholder" v-show="errors.has('municipio')">Campo requerido, solo caracteres</span>-->
              </div>
              <div class="col-md-4">
                 <span class="span-placeholder" for="estados">Estado</span>
                <select name="estados" id="estados" v-model="estado" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
                </select>
              </div>

              <div class="col-md-4">
                 <span class="span-placeholder" for="rfc">RFC (con homoclave)</span>
                <input class="vs-input"  label="RFC (con homoclave)" v-model="rfc" name="rfc" v-validate="'min:13|max:13'" />
              <span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
                <!--<span class="text-danger span-placeholder" v-show="errors.has('rfc')">Campo requerido, solo caracteres</span>-->
              </div>
              <div class="col-md-4">
                <ul class="con-s" style="margin-top:5%;margin-left:5%">
                  <li>
                    <span for="">¿Cuenta con una tarjeta de crédito vigente?</span>
                    <vs-switch v-model="tarjetaCredito"/>
                  </li>
                </ul>
              </div>
              
              <div class="col-md-4">
                <span class="span-placeholder" for="digitosTrajetaCredito">Últimos 4 dígitos</span>
                <input class="vs-input" label="Últimos 4 dígitos" v-model="digitosTrajetaCredito" name="digitosTrajetaCredito" v-validate="'required|min:4|max:4'"  v-on:keypress="isNumber($event)" :disabled="!tarjetaCredito"/>
                <!--<span class="text-danger span-placeholder" v-show="errors.has('digitosTrajetaCredito')">El campo debe tener 4 caracteres</span>-->
              </div>
              <div class="row w-full">
              <div class="col-md-3">
                <vs-button color="primary" @click.prevent="registraDatosBuro()" :disabled="!validateForm">Continuar</vs-button>
              </div>
              </div>  
            </div>
            <br>
            <div class="vx-col w-full sm:w-full md:w-1/5 lg:w-1/5 rounded-lg "></div>
        </vx-card>    
</template>
<script>

export default {
  components: {
   
  },
  props: {
    caso: {
      type: Object,
      required: true
    },
  },
  data(){
		return{
      direccionIne:'',
      municipio:'',
      estado:'',
      rfc:'',
      tarjetaCredito:false,
      digitosTrajetaCredito:'',
      estados:[],

    }
  },
  mounted(){
    this.getApikey()
    this.getEstados()
    //this.consultaABuro()
    //console.log(this.caso)

  },
  computed:{ 
    validaTarjeta(){
    return (this.tarjetaCredito == true && this.digitosTrajetaCredito.length == 4) || (this.tarjetaCredito == false && this.digitosTrajetaCredito.length == '')
    } , 
    validateForm(){
      return this.direccionIne != '' && this.municipio != '' && this.estado != '' && this.rfcState != '' && this.validaTarjeta 
    },
    rfcState() {
      const regex = /^([A-Z,Ñ,&,a-z,ñ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})$/
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const matches = regex.test(this.rfc);
      return this.rfc == null || matches ? true:false
    }
  },
  methods:{

    numberFormat(number){
      return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    getEstados(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraDatosBuro(){
      var objRequestConsultaBuro =   { 
            strApiKey: this.key,
            strMetodo: "RegistraDatosBuroInmobiliaria",
            objParametros : {		
                Solicitante : this.caso.email,
                DireccionIne : this.direccionIne,
                Municipio : this.municipio,
                Estado : this.estado,
                Rfc : this.rfc,
                TarjetaCredito : this.tarjetaCredito,
                TarjetaCreditoDigitos : this.digitosTrajetaCredito
         }
        }
         this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
             this.$router.push('/buroInmobiliaria/'+this.caso.email+'/'+this.caso.IdCaso).catch(() => {})
              //window.open('http://localhost:8080/app/buroInmobiliaria/'+this.caso.email+'/'+this.caso.IdCaso);
            //console.log(response.data.objContenido)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraDatosBuroInmobiliaria',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    }
  }
}
</script>